/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.qr-not-found-container {
    .qr-not-found-legend {
        text-align: center;
    }
}

.qr-not-found-container,
.qr-activation-container {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
    .qr-header {
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        .qr-header-image {
            width: 100%;
            height: 90%;
        }
        .logo {
            height: 60px;
            margin-bottom: 90px;
        }
        p {
            margin-top: 20px;
            font-family: t.$poppins-bold;
            font-size: 65px;
            line-height: 20px;
            margin: 0;
        }
        h1 {
            font-family: t.$poppins-extrabold;
            color: white;
            font-size: 75px;
            margin: 20px 0;
            font-style: italic;
        }
    }
    .qr-introduction {
        border-radius: 20px 20px 0 0;
        background: rgb(29, 3, 68);
        background: linear-gradient(
            360deg,
            rgba(29, 3, 68, 1) 0%,
            rgba(67, 43, 116, 1) 100%
        );
        padding: 65px 50px 65px 15px;
        h1 {
            font-size: 40px;
            font-family: t.$poppins-bold;
        }
        p {
            font-size: 20px;
        }
        .label {
            font-size: 24px;
            font-family: t.$poppins-extrabold;
        }
    }
    .bg-container {
        background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        .qr-code-container {
            margin: 85px 0 210px;
            display: flex;
            justify-content: center;
            .qr-code {
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    font-size: 50px;
                    font-family: "Poppins-ExtraBold";
                    margin-bottom: 0;
                    margin-top: 20px;
                    text-align: center;
                }
                p {
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                }
                .qr-box {
                    margin-top: 30px;
                    padding: 30px 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(0, 0, 0, 0.2);
                    font-size: 20px;
                    border-radius: 20px;
                    max-width: 800px;
                    width: 100%;
                    text-align: center;
                    h3 {
                        margin: 0;
                        font-family: "Poppins-SemiBold";
                    }
                    .qr-image {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                h5 {
                    font-family: t.$poppins-regular;
                    font-size: 15px;
                    font-weight: 500;
                    span {
                        font-family: t.$poppins-extrabold;
                        font-style: italic;
                    }
                }
                .inactive {
                    h1 {
                        font-size: 35px;
                    }
                    p {
                        font-size: 17px;
                    }
                    h3 {
                        font-family: t.$poppins-bold;
                        line-height: 40px;
                    }
                    h4 {
                        font-family: t.$poppins-semibold;
                    }
                    h2 {
                        font-size: 40px;
                        font-family: t.$poppins-extrabold;
                        letter-spacing: 2px;
                    }
                }
            }
        }
        .qr-second-video {
            display: flex;
            align-items: center;
            h1 {
                font-size: 40px;
                font-family: t.$poppins-bold;
                line-height: 60px;
            }
            p {
                font-size: 18px;
                line-height: 30px;
            }
            .label {
                font-size: 24px;
                font-family: t.$poppins-extrabold;
            }
        }
    }
    .qr-introduction,
    .qr-second-video {
        .video-responsive {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            padding-top: 30px;
            position: relative;
        }
        .video-responsive iframe,
        .video-responsive object,
        .video-responsive embed {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .qr-footer {
        background: #15002a;
        padding: 10px 0;
        text-align: center;
        margin-top: 150px;
    }
}

@media screen and (max-width: 899px) {
    .qr-activation-container {
        padding: 20px;
        .qr-header {
            margin-bottom: 15px;
            p {
                line-height: 74px;
                text-align: center;
            }
            h1 {
                margin: 0;
                text-align: center;
            }
            .qr-header-image {
                display: none !important;
            }
        }
        .qr-introduction {
            padding: 20px 0;
            background: none;
        }
        .bg-container {
            background-image: none;
            .qr-code-container {
                margin: 50px 0 0;
                .qr-code {
                    h1 {
                        margin-top: 0;
                        font-size: 40px !important;
                    }
                    .qr-box {
                        padding: 20px;
                        h3 {
                            margin: 20px 0 25px !important;
                            font-family: "Poppins-Bold";
                        }
                        .qr-image {
                            display: flex;
                            justify-content: center !important;
                        }
                    }
                    .inactive h2 {
                        font-size: 40px;
                    }
                }
            }
        }
        .qr-footer {
            margin-top: 50px;
        }
        .video-responsive {
            margin: 30px auto !important;
            display: flex;
            justify-content: center;
        }
    }
}

.qr-activation-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-qr-image {
    width: 100%;
    height: auto;
}
