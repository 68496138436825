.gift-coverage-container {
    width: 100%;
}
.gift-coverage-container .font-bold {
    font-family: "Poppins-Bold";
}
.gift-coverage-container .font-semibold {
    font-family: "Poppins-SemiBold";
}
.gift-coverage-container .font-medium {
    font-weight: 500;
    font-family: "Poppins-Medium";
}
.gift-coverage-container .purple-color {
    color: #a361ff;
}
.gift-coverage-container .white-color {
    color: #fff;
}
.gift-coverage-container .link-coverage-continue {
    color: #8971ad;
    text-decoration: none;
    cursor: pointer;
    margin-top: 25px;
    text-align: center;
    font-size: 14px;
    background-color: rgb(233, 227, 245);
    width: 50%;
    padding: 10px;
    border-radius: 50px;
    font-weight: 600;
    margin: 0 auto;
}
@media screen and (max-width: 899px) {
    .gift-coverage-container .link-coverage-continue {
        color: #fff;
        background-color: rgba(36, 5, 100, 0.26);
    }
}
.gift-coverage-container .link-coverage-map {
    text-decoration: none;
    cursor: pointer;
}
@media screen and (max-width: 899px) {
    .gift-coverage-container .link-coverage-map {
        text-align: center;
    }
}
.gift-coverage-container .chart-coverage {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: #fff;
    width: 350px;
}
.gift-coverage-container .chart-coverage .coverage-excelent {
    position: absolute;
    bottom: 15px;
    left: 245px;
}
.gift-coverage-container .chart-coverage .coverage-good {
    position: absolute;
    left: 250px;
    top: 40px
}
.gift-coverage-container .chart-coverage .coverage-bad {
    position: absolute;
    bottom: 15px;
    right: 260px;
}
.gift-coverage-container .chart-coverage .coverage-regular {
    position: absolute;
    right: 250px;
    top: 40px
}

